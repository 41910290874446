<template>
  <div>
    <div class="paper-mian">
      <div>
       <template v-if="language=='zh'">
        <myimage :url='url'></myimage>
      </template>
      <template v-if="language=='TW'">
        <myimage :url='urlTW'></myimage>
      </template>
      <template v-if="language=='En'">
        <myimage :url='urlEn'></myimage>
      </template>
      </div>
      <div :class="screenWidth >= 992 ? 'paper-service' : 'paper-service-sm'">
        <div class="backgroundfff-serve">
          <div class="english-yellow">{{$t("paper.Projectprocess")}}</div>
          <div class="service-con">
            <div class="servicetop">{{$t("paper.项目流程")}}</div>
            <div class="servicebot"></div>
          </div>
          <div>
            <img src="../../assets/image/paper/ptop.png" alt=""/>
          </div>
        </div>
      </div>
      <div class="backgroundAF6">
        <div :class="screenWidth >= 992 ? 'paper-service' : 'paper-service-sm'">
          <div class="backgroundAF6-serve">
            <div class="english-yellow">{{$t("paper.Thesiscounselingprocess")}}</div>
            <div class="service-con">
              <div class="servicetop">{{$t("paper.论文辅导流程")}}</div>
              <div class="servicebot"></div>
            </div>
            <div>
              <img src="../../assets/image/paper/paper3.png" alt=""/>
            </div>
          </div>
        </div>
      </div>
      <div
          :class="screenWidth >= 992 ? 'paper-service' : 'paper-service-sm'"
          style="padding-top: 50px"
      >
        <div class="background-serve">
          <div class="english-yellow">{{$t("paper.Coachingprocess")}}</div>
          <div class="service-con">
            <div class="servicetop">{{$t("paper.Essay辅导流程")}}</div>
            <div class="servicebot"></div>
          </div>
          <div>
            <img src="../../assets/image/paper/paper4.png" alt=""/>
          </div>
        </div>
      </div>
      <div class="backgroundF7F">
        <div :class="screenWidth >= 992 ? 'paper-service' : 'paper-service-sm'">
          <div class="backgroundF7F-serve">
            <div class="english-yellow">{{$t("paper.Servicehighlights")}}</div>
            <div class="service-con">
              <div class="servicetop">{{$t("paper.服务亮点")}}</div>
              <div class="servicebot"></div>
            </div>
            <div>
              <img src="../../assets/image/paper/paper5.png" alt=""/>
            </div>
          </div>
        </div>
      </div>
      <div
          :class="screenWidth >= 992 ? 'paper-service' : 'paper-service-sm'"
          style="padding-top: 50px"
      >
        <div class="background-serve">
          <div class="english-yellow">{{$t("paper.Serviceguarantee")}}</div>
          <div class="service-con">
            <div class="servicetop">{{$t("paper.服务保障")}}</div>
            <div class="servicebot"></div>
          </div>
          <div>
            <img style="height:277px" src="../../assets/image/paper/paper6.png" alt=""/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import myimage from "@/components/myimage";

export default {
  components: {
    myimage,
  },
  data() {
    return {
      screenWidth: document.body.clientWidth,
      screenHeight: document.body.clientHeight,
      // bannerList: [
      //   "https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fhbimg.b0.upaiyun.com%2Fe82575e1bc472edaeb120bb8976165ff7e8c71dd6cdd5-HVrxdx_fw658&refer=http%3A%2F%2Fhbimg.b0.upaiyun.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1654938807&t=c4ca465f95bca8716738d4c2c26a441e",
      //   "https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fhbimg.b0.upaiyun.com%2Fe82575e1bc472edaeb120bb8976165ff7e8c71dd6cdd5-HVrxdx_fw658&refer=http%3A%2F%2Fhbimg.b0.upaiyun.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1654938807&t=c4ca465f95bca8716738d4c2c26a441e",
      //   "https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fhbimg.b0.upaiyun.com%2Fe82575e1bc472edaeb120bb8976165ff7e8c71dd6cdd5-HVrxdx_fw658&refer=http%3A%2F%2Fhbimg.b0.upaiyun.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1654938807&t=c4ca465f95bca8716738d4c2c26a441e",
      // ],
      language:'zh',
      url:require('@/assets/image/lwfd.png'),
      urlTW: require('@/assets/image/lwfdTW.png'),
      urlEn: require('@/assets/image/lwfdEn.png'),
    };
  },
  created () {
    this.language=localStorage.getItem('language');
  },
  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.documentElement.clientWidth; //实时宽度
        window.screenHeight = document.documentElement.clientHeight; //实时高度
        console.log(this.screenWidth, this.screenHeight);
        that.screenWidth = window.screenWidth;
        that.screenHeight = window.screenHeight;
      })();
    };
  },
};
</script>

<style lang="scss" scoped>
.paper-mian {
  width: 100vw;
}

.paper-service-sm {
  width: 90%;
  margin: 0 auto;
  padding-bottom: 50px;
}

.paper-service {
  width: 75%;
  margin: 0 auto;
  padding-bottom: 50px;
}

.paper-service > div,
.paper-service-sm > div {
  position: relative;
  top: 0;
}

.backgroundfff-serve {
  height: 800px;
  margin-top: 70px;
}

.backgroundfff-serve img {
  width: 1176px;
  height: 621px;
  margin-top: 100px;
}

.backgroundAF6 {
  background: #fefaf6;
  padding: 70px 0;
}

.backgroundAF6-serve {
  height: 260px;
}

.backgroundAF6-serve img {
  width: 1176px;
  height: 141px;
  margin-top: 100px;
}

.backgroundF7F {
  background: #f7f7f7;
  padding: 70px 0;
}

.backgroundF7F-serve {
  height: 450px;
}

.backgroundF7F-serve img {
  width: 1176px;
  height: 280px;
  margin-top: 100px;
}

.english-yellow {
  color: #fe992b;
  font-size: 44px;
  opacity: 0.3;
}

.service-con {
  width: 100%;
  position: absolute;
  top: 15px;
}

.servicetop {
  font-size: 32px;
  color: #333;
  font-weight: bold;
  padding: 10px 0;
}

.servicebot {
  width: 150px;
  height: 6px;
  background: #fe992b;
  border-radius: 3px;
  margin: 0 auto;
}

.background-serve img {
  height: 330px;
  width: 1176px;
  margin-top: 100px;
}
</style>
